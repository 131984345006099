import React, { useState } from 'react';
import { useDocuments } from '../Shared/ApiServiceHook';
import { Stack, Button, Table, TableHead, TableBody, TableContainer, TableRow, TableCell, Card, CardContent, Typography, IconButton, TextField, Paper, Grid, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { uuidv4 } from '../../services/samportUtils';
import { formatSize } from '../../services/formatUtils';
import * as dayjs from 'dayjs';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const DocumentUpload = () => {
    const [files, setFiles] = useState([]);
    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);
    const [isUploading, setIsUploading] = useState(false);

    const docApi = useDocuments();

    const handleFileChange = (ev) => {
        const nfs = [];

        for (let i = 0; i < ev.target.files.length; i++) {
            console.log('reading file ' + i);
            let f = ev.target.files[i];

            let msg = '';
            if (files.find(e => e.formFile.name == f.name)) {
                msg = 'Duplicate filename!';
            }

            nfs.push({
                recordID: uuidv4(),
                formFile: f,
                description: '',
                lastModified: f.lastModified,
                tags: [],
                message: msg
            });
        }

        setFiles(currentFiles => [...currentFiles, ...nfs]);
    };

    const handleUpload = async () => {
        setIsUploading(true);

        const result = await docApi.uploadFiles(1, files, tags);
    
        if (result) {
            setTags([]);
            setFiles([]);
        }
        
        setIsUploading(false);
    }

    const deleteFile = (f) => {
        const n_files = files.filter(e => e.recordID !== f.recordID);

        setFiles(n_files);
    }

    const getSize = (size) => {
        formatSize(size);
    }

    const handleDescriptionChange = (recordID, newDescription) => {
        const updatedFiles = files.map(file => {
            if (file.recordID === recordID) {
                return { ...file, description: newDescription };
            }
            return file;
        });

        setFiles(updatedFiles);
    };

    const addTag = () => {
        setTags([...tags, tag]);
        setTag('');
    }

    const removeTag = (t) => {
        const n_tags = tags.filter(e => e !== t);

        setTags(n_tags);
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
                {isUploading && (<progress />)}
                <Card>
                    <CardContent>
                        <Stack spacing={4}>
                            <Typography>Files: {files.length}</Typography>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Typography align="center">Tags:</Typography>
                                {tags.map(t => (
                                    <Paper>{t} <IconButton onClick={() => removeTag(t)}><DeleteIcon /></IconButton></Paper>
                                ))}
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <TextField
                                    size='small'
                                    label="Tag"
                                    value={tag}
                                    onChange={(ev) => setTag(ev.target.value)}></TextField>
                                <Button onClick={addTag}>Add</Button>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <Button
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Choose files
                            <VisuallyHiddenInput type="file" multiple onChange={handleFileChange} />
                        </Button>
                    </Grid>
                    <Grid item xs={6} >
                        <Button fullWidth variant="contained" color="primary" onClick={handleUpload}>Upload Files</Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Filename</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Size</TableCell>
                                <TableCell>LastUpdated</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map(f => (
                                <TableRow key={f.recordID}>
                                    <TableCell>{f.formFile.name}</TableCell>
                                    <TableCell>{f.formFile.type}</TableCell>
                                    <TableCell>{getSize(f.formFile.size)}</TableCell>
                                    <TableCell>{dayjs(f.formFile.lastModified).format("YYYY-MM-DD")}</TableCell>
                                    <TableCell>
                                        <TextField type="text" onChange={(ev) => handleDescriptionChange(f.recordID, ev.target.value)} value={f.description} />
                                    </TableCell>
                                    <TableCell>{f.message}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => deleteFile(f)}><DeleteIcon /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </React.Fragment>
    );
}

export default DocumentUpload;