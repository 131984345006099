import React, {useState, useEffect} from 'react';
import { useReporting } from '../Shared/ApiReportingHook';
import { Checkbox, FormControlLabel, FormGroup, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AccountingEventInstanceView from '../AccountingEventInstances/AccountingEventInstanceView';
import ReportAccountingEvents from './ReportAccountingEvents';
import { formatCurrency } from '../../services/formatUtils';


const ReportAccounts = ({mode}) => {
    const api = useReporting();
    const [filter, setFilter] = useState({ year: 2024, detailed: false});

    const [selectedAccountNumber, setSelectedAccountNumber] = useState(0);

    const [report, setReport] = useState([]);

    useEffect(() => {
        refresh();
    }, [filter]);

    const refresh = async () => {
        const response = await api.getAccountsReport(filter.year, filter.detailed, mode);

        if (response) {
            setReport(response);
        }
    }

    const toggleDetailed = () => {
        const ndetailed = !filter.detailed;

        setFilter({...filter, detailed: ndetailed});
    }

    const lineClicked= (accountNumber) => {
        if (selectedAccountNumber == accountNumber) {
            setSelectedAccountNumber(0);
        } else {
            setSelectedAccountNumber(accountNumber);
        }
    }

    return (
        <React.Fragment>
            <Stack spacing={2}>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={filter.detailed} onChange={toggleDetailed} />} label="Vis detaljer" />
            </FormGroup>
            <TableContainer sx={{maxHeight: "40vh"}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Account</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell sx={{ width: "50px"}}>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.map((e, index) => (
                            <TableRow onClick={() => lineClicked(e.accountNumber)} key={e.accountNumber} sx={{backgroundColor: selectedAccountNumber == e.accountNumber ? 'primary.main' : (index % 2 === 0 ? 'background.paper' : 'grey.100') }}>
                                <TableCell sx={{color: selectedAccountNumber == e.accountNumber ? 'primary.contrastText' : 'primary'}}>{e.accountNumber}</TableCell>
                                <TableCell sx={{color: selectedAccountNumber == e.accountNumber ? 'primary.contrastText' : 'primary'}}>{e.description}</TableCell>
                                <TableCell sx={{color: selectedAccountNumber == e.accountNumber ? 'primary.contrastText' : 'primary', textAlign: 'right'}}>{formatCurrency(e.totalAmount, 0)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ReportAccountingEvents hideFilters={true} accountNumber={selectedAccountNumber} givenFilters={{hideAttached: false, counterpartSearch: '', typeSearch: '', year: mode === 1 ? 0 : 2024 }} maxHeight="47vh" />
            </Stack>
        </React.Fragment>
    )
}

export default ReportAccounts;