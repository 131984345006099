import React, { useEffect, useState } from 'react';
import { useAccountingEventInstances, useAccountingEventInstanceReports } from '../Shared/ApiServiceHook';
import GenericModal from '../Shared/GenericModal';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import { formatCurrency, formatDate } from '../../services/formatUtils';
import AccountingSummaryView from '../AccountingEventInstances/AccountingSummaryView';
import { Typography, Stack, Card, CardContent, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import AccountingEventInstanceView from '../AccountingEventInstances/AccountingEventInstanceView';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';

const getDefaultFilters = () => {
    return { hideAttached: false, counterpartSearch: '', typeSearch: '', year: 2024  };
}

const ReportAccountingEvents = ({ key, givenFilters = getDefaultFilters(), onSelectionChanged = (sel) => {}, hideFilters = false, accountNumber = 0, maxHeight="80vh" }) => {
    const [report, setReport] = useState([]);
    const [instance, setInstance] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [types, setTypes] = useState([]);

    const [filters, setFilters] = useState(givenFilters);

    const api = useAccountingEventInstanceReports();
    const instanceApi = useAccountingEventInstances();

    const [selectedRows, setSelectedRows] = useState([]);

    const fetch = async () => {
        let f_report = await api.list({accountNumber: accountNumber});

        console.log('f_report', f_report);
        let f_types = [];

        if (!f_report?.accountingEventInstances) {
            return;
        }

        f_report.accountingEventInstances.map(a => {
            if (!f_types.some(f => f === a.accountingEventText)) {
                f_types.push(a.accountingEventText);
            }
        });
        f_types.sort();
        setTypes(f_types);

        f_report.accountingEventInstances = f_report.accountingEventInstances.filter(r => {
            if (filters.hideAttached && r.attachments.length > 0) {
                return false;
            }

            if (filters.counterpartSearch && filters.counterpartSearch !== '' && !r.counterpartText.toLowerCase().includes(filters.counterpartSearch.toLowerCase()))
            {
                return false;
            }

            if (filters.typeSearch && filters.typeSearch !== '' && !r.accountingEventText.toLowerCase().includes(filters.typeSearch.toLowerCase()))
            {
                return false;
            }

            if (filters.year && filters.year > 0 && filters.year != new Date(r.eventDate).getFullYear() && filters.year != new Date(r.settledDate).getFullYear()) {
                return false;
            }

            return true;
        });

        setReport(f_report);
    };

    useEffect(() => {
        fetch();
    }, [key, filters, accountNumber]);

    useEffect(() => {
        onSelectionChanged(selectedRows);
    }, [selectedRows]);

    const handleClose = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = (inst) => {
        setInstance(inst);
        setModalIsOpen(true);
    }

    const cols = [
        {
            field: 'actions',
            headerName: '',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Stack direction='row'>
                    <IconButton onClick={() => handleOpenModal(params.row)} aria-label="view">
                        <VisibilityIcon />
                    </IconButton>
                </Stack>
            ),
        },
        { field: 'accountingEventInstanceID' },
        { field: 'eventDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
            return formatDate(params.value);
        }},
        { field: 'accountingEventModeText', headerName: 'Mode', width: '100px', flex: 1},
        { field: 'accountingEventText', headerName: 'Type', width: '100px', flex: 1},
        { field: 'accountingEventInstanceText', headerName: 'Item', width: '100px', flex: 2},
        { field: 'counterpartText', headerName: 'Counterpart', width: '100px', flex: 2},
        { field: 'attachments', headerName: 'Attachments', width: '50px', flex: 1, valueFormatter: (params) => {
            return params && params.value && params.value.length;
        }},
        { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
            return formatCurrency(params.value);
        }}
    ];

    if (!report?.accountingEventInstances) {
        console.log('accevinstances', report?.accountingEventInstances);
        return (<p>loading...</p>);
    }

    const renderFilters = () => {
        if (hideFilters) {
            return;
        }

        return (
            <Stack spacing={2}>
                <FormControl>
                    <InputLabel id="ae-year-label">Year</InputLabel>
                    <Select
                        labelId="ae-year-label"
                        id="ae-year"
                        label="Year"
                        defaultValue={0}
                        displayEmpty
                        value={filters.year}
                        onChange={(e) => setFilters({ ...filters, year: e.target.value })}>
                        <MenuItem key={0} value={0}><em>All</em></MenuItem>
                        <MenuItem key={2022} value={2022}>2022</MenuItem>
                        <MenuItem key={2023} value={2023}>2023</MenuItem>
                        <MenuItem key={2024} value={2024}>2024</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="ae-type-label">Type</InputLabel>
                    <Select
                        labelId="ae-type-label"
                        id="ae-type"
                        label="Type"
                        defaultValue={0}
                        displayEmpty
                        value={filters.typeSearch}
                        onChange={(e) => setFilters({ ...filters, typeSearch: e.target.value })}>
                        <MenuItem key={0} value={0}><em>All</em></MenuItem>
                        {types.map(t => (
                            <MenuItem key={t} value={t}>{t}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            
            {/* <AccountingSummaryView summary={report.summary} asExpandable={true} /> */}
            {renderFilters()}

            <DataGrid sx={{maxHeight: maxHeight}}
                        rowHeight={25}
                        rows={report.accountingEventInstances}
                        columns={ cols }
                        getRowId={(r) => r.accountingEventInstanceID}
                        columnVisibilityModel= {{
                            accountingEventInstanceID: false 
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[50, 100]}
                        checkboxSelection
                        localeText={dataGridNbNO}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setSelectedRows(newRowSelectionModel);
                        }}
                        rowSelectionModel={selectedRows}
                    />
            <GenericModal open={modalIsOpen} handleClose={handleClose} width={"90vw"} height={"90vh"}>
                <AccountingEventInstanceView instance={instance} />
                {/* onClose={handleClose}  */}
            </GenericModal>
        </Stack>
    );
};

export default ReportAccountingEvents;
