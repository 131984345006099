import React, { useState } from 'react';
import { Button, List, TextField, InputLabel, TableHead, Select, Stack, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useBankTransactionImport } from '../Shared/ApiServiceHook';
import { DataGrid, nbNO as dataGridNbNO } from '@mui/x-data-grid';
import { formatCurrency, formatDate, formatDecimal } from '../../services/formatUtils';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const BankTransactionImport = () => {
    const { id } = useParams();
    const [importText, setImportText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [parseResponse, setParseResponse] = useState({});

    const [rowSelectionmodel, setRowSelectionModel] = useState([]);
    const [dateFormat, setDateFormat] = useState("yyyy-MM-dd");

    const bankTransactionImport_api = useBankTransactionImport();

    const handlePaste = (event) => {
        setImportText(event.target.value);
    };

    const doParse = async () => {
        setIsLoading(true);

        const res = await bankTransactionImport_api.bankTransactionImportParse(id, importText, dateFormat);

        setParseResponse(res);
        setIsLoading(false);
    }

    const doImport = async () => {
        setIsLoading(true);

        const selected = parseResponse.parsedTransactions.filter(r => rowSelectionmodel.includes(r.sourceRow));
        const res = await bankTransactionImport_api.bankTransactionImport(id, selected);

        setIsLoading(false);
    }

    const topPart = (
        <section>
            <Stack>
                <InputLabel>Date format</InputLabel>
                <Select value={dateFormat} onChange={(ev) => setDateFormat(ev.target.value)}>
                    <MenuItem value={"yyyy-MM-dd"}>yyyy-MM-dd</MenuItem>
                    <MenuItem value={"dd.MM.yyyy"}>dd.MM.yyyy</MenuItem>
                    <MenuItem value={"dd/MM/yyyy"}>dd/MM/yyyy</MenuItem>
                    <MenuItem value={"MM/dd/yyyy"}>MM/dd/yyyy</MenuItem>
                </Select>
            </Stack>
            <TextField
                label="Paste bank transactions here"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={importText}
                onChange={handlePaste}
                placeholder="Paste bank transactions here..."
                inputProps={{style: {fontSize: '10px'}}}
                readOnly={isLoading}
            />
            <Box display="flex" justifyContent="space-between">
                <Button onClick={doParse}>Parse</Button>
                <Button onClick={doImport}>Import</Button>
            </Box>
        </section>
    );

    let parsed = (
        <div></div>
    );

    if (parseResponse && parseResponse.parsedTransactions) {
        const cols = [
            { field: 'success', headerName: 'Success', width: '50px', flex: 1, type:'boolean'},
            { field: 'sourceRow', headerName: 'SourceRow', width: '50px', flex: 1 },
            { field: 'bookDate', headerName: 'Date', width: '50px', flex: 1 , valueFormatter: (params) => {
                return formatDate(params.value);
            }},
            { field: 'amount', headerName: 'Amount', width: '50px', flex: 1, type: 'number', valueFormatter: (params) => {
                return formatDecimal(params.value);
            }},
            { field: 'description', headerName: 'Desc', width: '100px', flex: 3},
            { field: 'comment', headerName: 'ParserMsg', width: '100px', flex: 2},
        ];

        parsed = (
            <div>
                <p>{parseResponse.message}</p>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>IncomingBalance</TableCell>
                                <TableCell>OutgoingBalance</TableCell>
                                <TableCell>InAmount</TableCell>
                                <TableCell>OutAmount</TableCell>
                                <TableCell>NetAmount</TableCell>
                                <TableCell>Success/Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{formatDate(parseResponse.startDate)}</TableCell>
                                <TableCell>{formatDate(parseResponse.endDate)}</TableCell>
                                <TableCell>{formatCurrency(parseResponse.incomingBalance)}</TableCell>
                                <TableCell>{formatCurrency(parseResponse.outgoingBalance)}</TableCell>
                                <TableCell>{formatCurrency(parseResponse.inAmount)}</TableCell>
                                <TableCell>{formatCurrency(parseResponse.outAmount)}</TableCell>
                                <TableCell>{formatCurrency(parseResponse.netAmount)}</TableCell>
                                <TableCell>{parseResponse.parsedTransactions.reduce((count, p) => p.success ? count + 1 : count, 0)}/{parseResponse.parsedTransactions.length}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ height: '700px', width: '100%' }}>
                    <DataGrid
                        rowHeight={25}
                        rows={parseResponse.parsedTransactions}
                        columns={cols}
                        getRowId={(r) => r.sourceRow}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 100 },
                            },
                        }}
                        pageSizeOptions={[50, 100, 500]}
                        checkboxSelection
                        localeText={dataGridNbNO}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        rowSelectionModel={rowSelectionmodel}
                    />
                    
                </div>
            </div>
        )
    }

    return (
        <div style={{ marginTop: "25px" }}>
            <div>
                {topPart}
            </div>
            <div>
                {parsed}
            </div>
        </div>
    );
};

export default BankTransactionImport;
